html,
body {
    height: 100%;
}
body {
    margin: 0;
    overflow: hidden;
}
/** Scrollable **/
$bg-color-scroll: #aab2bf;
$bg-color-scroll-2: #d9d9d9;
$border-radius-scroll: 4px;
$width-scroll: 8px;
$height-scroll: 8px;

$primary-color: #00437b;

@mixin scroll-custom {
    &::-webkit-scrollbar {
        width: $width-scroll;
        height: $height-scroll;
    }
    &::-webkit-scrollbar-thumb {
        background: $bg-color-scroll;
        border-radius: $border-radius-scroll;
    }
    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 $border-radius-scroll $bg-color-scroll-2;
        border-radius: $border-radius-scroll;
    }
    scrollbar-color: $bg-color-scroll $bg-color-scroll-2;
    scrollbar-width: thin;
}
.custom-scrollbar {
    @include scroll-custom();
}
/** end scroll **/
a:focus,
button:focus,
input:focus,
textarea:focus {
    outline: none;
}

.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
    .ant-input {
        padding: 4px 6px !important;
    }
}
.editable-row .ant-form-item-explain {
    position: absolute;
    top: 100%;
    font-size: 12px;
}
.ant-input-search
    > .ant-input-group
    > .ant-input-group-addon:last-child
    .ant-input-search-button {
    background-color: #00437b;
    border-color: #00437b;
}
.ant-modal-mask {
    transition: all 0.5s;
    background-color: transparent;
    opacity: 0;
}
.ant-select-selector {
    padding: 0 !important;
}
.ant-table-thead > tr > th {
    font-weight: 600;
}
.ant-select-selection-item {
    font-weight: 600;
}
.tableName:hover .ant-typography {
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    padding: 4px 4px;
    min-height: 32px;
    width: 60%;
}
.tableName .ant-typography {
    border: 1px solid transparent;
    border-radius: 4px;
    padding: 4px 4px;
    min-height: 32px;
    width: 60%;
}
.editSidebar {
    min-height: 32;
    .ant-table-bordered {
        border: 1px soild red;
    }
    .ant-table-body {
        @include scroll-custom();
        min-height: calc(100vh - 217px);
    }
    .ant-table-expanded-row-fixed {
        min-height: calc(100vh - 251px);
    }
    .ant-empty-normal {
        min-height: calc(100vh - 312px);
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 30px;
        overflow: hidden;
    }
    .editable-cell {
        position: relative;
    }

    .editable-cell-value-wrap {
        padding: 5px 12px;
        cursor: pointer;
    }

    .ant-table-cell:hover .editable-cell-value-wrap {
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        padding: 4px 11px;
        min-height: 32px;
    }
    .ant-table-cell {
        padding: 5px 5px !important;
        border: 0.5px solid #edeeed;
        text-align: center;
    }
    textarea {
        min-height: 50px;
        max-height: 100px;
        text-align: center;
    }
}
.modal-extract-table {
    // .ant-table-body {
    //     @include scroll-custom();
    // }
    // .editable-cell {
    //     position: relative;
    // }

    .editable-cell-value-wrap {
        padding: 5px 12px;
        cursor: pointer;
    }
    .ant-table-cell:hover .editable-cell-value-wrap {
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        padding: 4px 11px;
        min-height: 32px;
    }
    .ant-table-cell {
        width: 100px !important;
        padding: 2px 2px !important;
        border: 0.5px solid #edeeed;
        text-align: center;
    }
    textarea {
        min-height: 50px;
        max-height: 100px;
        text-align: center;
        // @include scroll-custom();
    }
    th.ant-table-cell {
        cursor: all-scroll;
    }
    .ant-table-row .ant-table-row-level-0 {
        pointer-events: none !important;
    }
    thead > tr > th.column-disabled {
        pointer-events: none !important;
    }
}
.modal-extract-custom-wrap {
    pointer-events: none;
}
.extract-table-drag-line {
    border-color: $primary-color !important;
}
.highlight-btn {
    text-align: right;
}
/** pdfviewer **/

.fv__ui-pdfviewer {
    overflow: hidden;
    overflow-y: hidden;
    position: relative;
    height: 100%;
}

/** end pdfviewer **/

/** dialog **/
.container-dialog-content-custom {
    pointer-events: auto;
    & > * {
        box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 5px -1px,
            rgba(0, 0, 0, 0.14) 0px 5px 8px 0px,
            rgba(0, 0, 0, 0.12) 0px 1px 14px 0px;
    }
}
.confirm-field-matched-component {
    width: 450px;
    height: auto;
    max-height: 200px;
    border-radius: 10px;
    display: grid;
    grid-template-rows: 50px 1fr 50px;
    background: #ffffff;
    & > .confirm-field-matched-component-title {
        text-align: center;
        font-weight: 700;
        font-size: 16px;
        padding: 16px 24px;
        & > span {
            color: #389e0d;
        }
    }
    & > .confirm-field-matched-component-content {
        padding: 10px 8px 20px 8px;
        min-height: 50px;
        & > .confirm-field-matched-component-content-input {
            max-width: 300px;
            flex: 1 1 auto;
            height: 50px;
            max-width: 400px;
            border-radius: 4px !important;
            border: unset !important;
            background-color: rgba($color: #aab2bf, $alpha: 0.2) !important;
            & > input {
                background-color: transparent !important;
            }
        }
    }
    & > .confirm-field-matched-component-footer {
        display: flex;
        width: 100%;
        border-top: 1px solid #f0f0f0;
        & > * {
            height: 100%;
            flex: 1 1 auto;
            font-weight: 600;
            &:not(:last-child) {
                border-right: 1px solid #f0f0f0;
                // color: #389E0D
                // color: #389E0D
            }
        }
    }
}
.confirm-dialog-yes-no {
    width: 350px;
    height: auto;
    max-height: 150px;
    border-radius: 10px;
    display: grid;
    grid-template-rows: 50px 1fr 45px;
    background: #ffffff;
    & > .confirm-dialog-yes-no-title {
        text-align: center;
        font-weight: 700;
        font-size: 16px;
        padding: 16px 24px;
    }
    & > .confirm-dialog-yes-no-content {
        padding: 10px 8px 20px 8px;
        min-height: 50px;
        text-align: center;
    }
    & > .confirm-dialog-yes-no-footer {
        display: flex;
        width: 100%;
        border-top: 1px solid #f0f0f0;
        & > * {
            height: 100%;
            flex: 1 1 auto;
            font-weight: 600;
            &:not(:last-child) {
                border-right: 1px solid #f0f0f0;
                color: crimson;
            }
        }
    }
}
/** end dialog **/

// ant sub menu
.ant-menu-horizontal {
    line-height: initial !important;
    border: none !important;
}
.menu-item {
    background-color: none !important;
    border: none !important;
}
.ant-menu-sub {
    min-width: auto !important;
    padding: 5px !important;
}
// end ant sub menu
.ant-popover-buttons {
    text-align: center !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
